import * as yup from 'yup';
/**
 * AC calculator
 *
 *
 */

type Floor = 'last' | 'repeated';
type Dir = 'upper' | 'lower';

/**
 * AC calculator Data set reference
 */
export const ranges: Record<number, Record<Dir, Record<Floor, number>>> = {
  10: {
    upper: {
      last: 1.5,
      repeated: 1.5,
    },
    lower: {
      last: 1.5,
      repeated: 1.5,
    },
  },
  12: {
    upper: {
      last: 1.5,
      repeated: 1.5,
    },
    lower: {
      last: 2.25,
      repeated: 1.5,
    },
  },
  14: {
    upper: {
      last: 2.25,
      repeated: 1.5,
    },
    lower: {
      last: 2.25,
      repeated: 2.25,
    },
  },
  16: {
    upper: {
      last: 2.25,
      repeated: 2.25,
    },
    lower: {
      last: 3,
      repeated: 2.25,
    },
  },
  18: {
    upper: {
      last: 3,
      repeated: 2.25,
    },
    lower: {
      last: 3,
      repeated: 2.25,
    },
  },
  20: {
    upper: {
      last: 3,
      repeated: 2.25,
    },
    lower: {
      last: 3,
      repeated: 3,
    },
  },
  22: {
    upper: {
      last: 3,
      repeated: 3,
    },
    lower: {
      last: 3.75,
      repeated: 3,
    },
  },
  24: {
    upper: {
      last: 3.75,
      repeated: 3,
    },
    lower: {
      last: 3.75,
      repeated: 3,
    },
  },
  26: {
    upper: {
      last: 3.75,
      repeated: 3,
    },
    lower: {
      last: 3.75,
      repeated: 3.75,
    },
  },
  28: {
    upper: {
      last: 3.75,
      repeated: 3,
    },
    lower: {
      last: 4.5,
      repeated: 3.75,
    },
  },
  30: {
    upper: {
      last: 4.5,
      repeated: 3.75,
    },
    lower: {
      last: 4.5,
      repeated: 3.75,
    },
  },
  32: {
    upper: {
      last: 4.5,
      repeated: 3.75,
    },
    lower: {
      last: 4.5,
      repeated: 3.75,
    },
  },
  34: {
    upper: {
      last: 4.5,
      repeated: 3.75,
    },
    lower: {
      last: 5.25,
      repeated: 4.5,
    },
  },
  36: {
    upper: {
      last: 5.25,
      repeated: 3.75,
    },
    lower: {
      last: 5.25,
      repeated: 4.5,
    },
  },
  38: {
    upper: {
      last: 5.25,
      repeated: 4.5,
    },
    lower: {
      last: 5.25,
      repeated: 4.5,
    },
  },
  40: {
    upper: {
      last: 5.25,
      repeated: 4.5,
    },
    lower: {
      last: 6,
      repeated: 5.25,
    },
  },
  42: {
    upper: {
      last: 6,
      repeated: 4.5,
    },
    lower: {
      last: 6,
      repeated: 5.25,
    },
  },
};

export const calculateHp =
  (nearest: (arg0: number) => number) =>
  (args: { dir: Dir; floor: Floor; value: number }): number => {
    return ranges[nearest(args.value)]?.[args.dir]?.[args.floor] || 0;
  };

/**
 * Search for a number in a pre-defined range O(n*n)+O(n)
 */
const nearest =
  (range: number[]) =>
  (target: number): number => {
    return range.sort().reduce(function (prev, curr) {
      return Math.abs(curr - target) < Math.abs(prev - target) ? curr : prev;
    });
  };

/**
 * Use this one assuming the ranges are well defined and sorted
 */
const optimizedNearest =
  (min: number, max: number, steps: number) =>
  (target: number): number => {
    if (target < min) {
      return min;
    }

    if (target > max) {
      return max;
    }

    const index = Math.floor((target - min) / steps);
    const lower = min + index * steps;
    const upper = lower + steps;

    return target - lower < upper - target ? lower : upper;
  };

const isSymmetric = (range: number[]): boolean => {
  let flag = true;
  range.forEach((val, index) => {
    if (val && index && val - (range[index - 1] || 0) !== 2) {
      flag = false;
    }
  });

  return flag;
};

export function useAcCalculator() {
  /**
   *
   * define which nearest function going to be used based on the data set ranges
   */
  const rangesOnly = Object.keys(ranges).map(Number);
  const nearestFn = isSymmetric(rangesOnly)
    ? optimizedNearest(rangesOnly[0] || 0, rangesOnly[rangesOnly.length - 1] || 0, 2)
    : nearest(rangesOnly);

  const acCalculator = (args: { dir: Dir; floor: Floor; value: number }): number => {
    return calculateHp(nearestFn)(args);
  };

  return {
    acCalculator,
  };
}

export function useAcCalculatorForm() {
  const schema = yup.object({
    lastFloor: yup.mixed().oneOf(['last', 'repeated']).required() as yup.Schema<Floor>,
    roomDirection: yup.mixed().oneOf(['upper', 'lower']).required() as yup.Schema<Dir>,
    width: yup.number().required(),
    length: yup.number().required(),
  });

  const { isSubmitting, handleSubmit, meta, values } = useForm({
    validationSchema: toTypedSchema(schema),
  });

  return {
    isSubmitting,
    handleSubmit,
    meta,
    values,
  };
}
