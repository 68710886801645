<template>
  <component
    :is="as"
    class="Message bg-white p-4 flex items-center rounded-2xl relative"
    :class="[
      `Message--${type}`,
      `Message--${type}-container`,
      `text-${align}`,
      {
        'with-background': withBackground,
        'pr-25': withIcon,
      },
    ]"
  >
    <div
      v-if="withIcon"
      class="Message__icon [ absolute -bottom-2 -top-5 right-8 ] [ rounded-full ] [ flex items-center justify-center ] [ w-16 h-16 ]"
      :class="[type === 'danger' ? 'bg-red-50' : `Message--${type}`]"
    >
      <svg-icon
        :name="icon"
        class="stroke-current fill-current"
        width="30"
        height="30"
        :class="[type === 'danger' ? 'text-red-700' : `Message--${type}`]"
      />
    </div>

    <div class="ml-4 Message__Body w-full">
      <h5 v-if="$slots.title" role="alert" :aria-live="type === 'danger' ? 'assertive' : 'polite'">
        <slot name="title"></slot>
      </h5>
      <p v-if="$slots.subtitle" role="alert" :aria-live="type === 'danger' ? 'assertive' : 'polite'">
        <slot name="subtitle"></slot>
      </p>
    </div>
  </component>
</template>

<script setup lang="ts">
const props = defineProps({
  as: {
    type: String,
    default: 'div',
  },
  type: {
    type: String as PropType<'success' | 'danger' | 'info' | 'warning'>,
    required: true,
    validator: (value: string) => ['success', 'danger', 'info', 'warning'].includes(value),
  },
  align: {
    type: String as PropType<'center' | 'left' | 'right'>,
    default: 'left',
    validator: (value: string) => ['center', 'left', 'right'].includes(value),
  },
  withIcon: {
    type: Boolean,
    default: false,
  },
  withBackground: {
    type: Boolean,
    default: false,
  },
});

const icon = computed(() => {
  return {
    danger: 'alert_error',
    success: 'like',
    info: 'check',
    warning: 'error',
  }[props.type];
});
</script>

<style lang="postcss" scoped>
.Message {
  &--success {
    @apply text-green-700 bg-green-200;
    &.with-background {
      background-image: url('~/assets/icons/bg-success-pattern.svg');
    }
    &-container {
      @apply shadow-custom-success;
    }
  }
  &--info {
    @apply text-primary-700 bg-primary-100;
    &-container {
      @apply shadow-custom-info;
    }
  }
  &--warning {
    @apply text-tertiary-700 bg-tertiary-100;
    &-container {
      @apply shadow-custom-warning;
    }
  }
  &--danger {
    @apply text-red-700 bg-red-50;
    &.with-background {
      background-image: url('~/assets/icons/bg-error-pattern.svg');
    }
    &-container {
      @apply shadow-custom-error;
    }
  }

  &--danger &__Body {
    @apply text-primary-700;
    & > *:first-child {
      @apply text-red-700;
    }
  }

  &__Body {
    @apply flex flex-col;
  }
}
</style>
